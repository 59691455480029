import React from 'react'
import {
  BrowserRouter as Router, 
  Switch, 
  Route,
} from 'react-router-dom'

import Loading from '../core/loading/loading'

//imports
const NewsRoute   = React.lazy(() => import('./news_route'))
const PharmaRoute = React.lazy(() => import('./pharma_route'))
const HomeRoute   = React.lazy(() => import('./home_route'))
const NotFound    = React.lazy(() => import('../core/notfound/notfound'))

//route definitions
export default class Routing extends React.Component{
  constructor(props){
    super(props)
    localStorage.setItem('lang', 'en')
  }
  pageLang(){
    return localStorage.getItem('lang')
  }
  render(){
    return(
      <React.Fragment>
        <React.Suspense fallback = {<Loading />}>
          <Router>
            <Switch>
              <Route path = '/pharmaco' component = {PharmaRoute} />
              <Route path = '/news' component = {NewsRoute}/>
              <Route path = '/' component = {HomeRoute} />
              <NotFound />
            </Switch>
          </Router>
        </React.Suspense>
      </React.Fragment>
    )
  }
}