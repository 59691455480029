import Routing from './routes/routes'
import React from 'react';
import "./stylesheet/index.css"
import "./fonts/font.css"

export default class App extends React.Component{
  constructor(props){
    super(props)
    this.registerServiceWorker()
  }
  registerServiceWorker(){
    if('serviceWorker' in navigator){
    }
  }
  render(){
    return(
      <Routing />
    )
  }
}